import React, { Component } from "react"

/* Page 404 */
class Page404 extends Component {

    render = () => {
        return (
            <div className="page-404">
                <div className="page-404-title">404</div>
                <div className="page-404-text">Страница не найдена</div>
            </div>
        )
    }
}

export default Page404