import axios from "axios"
import { env } from '../constants'

/* List */
export const list = data => {
    const uri = `${env.startpoint}admin/product/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* List */
export const categories = data => {
    const uri = `${env.startpoint}admin/product/categories`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* GET */
export const get = data => {
    const uri = `${env.startpoint}admin/product/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* REMOVE */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/product/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Edit */
export const edit = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/product/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* REMOVE */
export const removeImage = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/product/remove-image`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/product/create`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}