import React, { Component } from "react"

/* Components */
import { Input } from "../../components"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { create } from '../../api/Admin'

/* Sweetalert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter, utils } from "../../helpers"



/* Page Create Admin */
class CreateAdmin extends Component {

    constructor() {
        super()

        this.state = {
            name: "",
            email: "",
            password: "",
            repeat: "",
            waiting: false
        }
    }

    /* Create action */
    create = () => {

        const { name, email, password, repeat } = this.state
        const { navigate } = this.props

        if (name === "" || name === null || name === undefined) {
            this._name.error("Введите имя")
            return
        }

        if (email === "" || email === null || email === undefined) {
            this._email.error("Введите Email")
            return
        }

        if (/\S+@\S+\.\S+/.test(email) === false) {
            this._email.error("Неверный формат Email")
            return
        }

        if (password === "" || password === null || password === undefined) {
            this._password.error("Введите пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        if (password !== repeat) {
            this._repeat.error("Пароли не совпадают")
            return
        }

        this.setState({ waiting: true })

        create({ name, email, password }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Админ успешно создан!", "success")).then(() => {
                    this.setState({ waiting: false })
                    navigate(-1)
                })
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            this.setState({ waiting: false })
        })
    }

    /* Generate Password */
    generatePassword = () => {
        const password = utils.generatePassword()
        this.setState({ password, repeat: password })
    }

    render = () => {

        const { name, email, password, repeat, waiting } = this.state

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Создать администратора</div>
                </div>

                <div className="page-content">
                    <div className="page-form">
                        <div className="page-form-box">

                            <Input ref={ref => this._name = ref} label="Имя" value={name} onChange={event => this.setState({ name: event.target.value })} />
                            <Input ref={ref => this._email = ref} label="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                            <Input ref={ref => this._password = ref} label="Придумайте пароль" value={password} onChange={event => this.setState({ password: event.target.value })} secure />
                            <div onClick={() => this.generatePassword()} className="form-generate-password">Сгенерировать пароль</div>
                            <Input ref={ref => this._repeat = ref} label="Повторите пароль" value={repeat} onChange={event => this.setState({ repeat: event.target.value })} secure />

                        </div>
                    </div>

                    <div className="page-form-bottom">
                        {waiting
                            ? <div className="page-form-button"><LoadingOutlined /></div>
                            : <div onClick={() => this.create()} className="page-form-button">Создать</div>
                        }
                    </div>

                </div>


            </div>
        )
    }

}

export default withRouter(CreateAdmin)