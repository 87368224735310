import moment from "moment"

/* Check Element */
export const check = (value, defaultValue = "") => {

    if (value !== undefined && value !== null) {
        return value
    }

    return defaultValue
}

/* PRICE CONVERTOR */
export const convertor = (price, symbol = "", isAfter = 0, space = "\u0020", defaultValue = "") => {

    /* Check the price for the void */
    if (price === null || price === undefined)
        return defaultValue

    const number = Number.parseFloat(price).toFixed(2)

    let before = ""
    let after = ""

    if (parseInt(isAfter) === 1) {
        after = "\u0020" + symbol
    }

    if (parseInt(isAfter) === 0) {
        before = symbol + "\u0020"
    }

    return before + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, space) + after
}




/* Show notification */
export const notification = (text, icon = null, timer = 1500) => {

    return {
        html: `<p class="notification">${text}</p>`,
        icon,
        color: 'white',
        background: 'black',
        showConfirmButton: false,
        position: 'top-right',
        timer,
        width: 400,
        backdrop: false,
        customClass: { icon: 'notification-icon', htmlContainer: 'notification-html', popup: 'notification-popup' }
    }
}

/* Get time */
export const getTime = time => {

    if (time) {

        const date = moment(time).format("YYYY-MM-DD")
        const today = moment(new Date()).format("YYYY-MM-DD")

        const yesterdayD = new Date()
        yesterdayD.setDate(yesterdayD.getDate() - 1)
        const yesterday = moment(yesterdayD).format("YYYY-MM-DD")

        let text = moment(date).format("Do MMM YYYY")

        if (moment(date).isSame(today, 'day')) {
            text = 'Сегодня'
        }

        if (moment(date).isSame(yesterday, 'day')) {
            text = 'Вчера'
        }

        return {
            time: moment(time).format("HH:mm"),
            date: text
        }
    }

    return { time: "-", date: "-" }

}


/* GET START AND END DATE  */
export const getDate = () => {

    const startDate = new Date()
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    startDate.setDate(startDate.getDate() - 30)


    const endDate = new Date()
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    return { startDate, endDate }
}



/* Generate Dates For filter */
export const getStatisticsDate = type => {

    /* Week */
    if (type === "week") {
        const start = moment().startOf('week').format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('week').format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Month */
    if (type === "month") {
        const start = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
        const end = moment().endOf('month').format('YYYY-MM-DD 23:59:59')
        return { start, end }
    }

    /* Default */
    const start = moment().format('YYYY-MM-DD 00:00:00')
    const end = moment().format('YYYY-MM-DD 23:59:59')

    return { start, end }
}


/* Generate Dates */
export const generateDates = (filter, start, end) => {

    let list = []

    if (filter && start && end) {

        if (filter === "day") {
            const date = moment(new Date(start))
            list = [
                { name: "00:00 - 03:00", start: date.format('YYYY-MM-DD 00:00:00'), end: date.format('YYYY-MM-DD 03:00:00') },
                { name: "03:00 - 06:00", start: date.format('YYYY-MM-DD 03:00:00'), end: date.format('YYYY-MM-DD 06:00:00') },
                { name: "06:00 - 09:00", start: date.format('YYYY-MM-DD 06:00:00'), end: date.format('YYYY-MM-DD 09:00:00') },
                { name: "09:00 - 12:00", start: date.format('YYYY-MM-DD 09:00:00'), end: date.format('YYYY-MM-DD 12:00:00') },
                { name: "12:00 - 15:00", start: date.format('YYYY-MM-DD 12:00:00'), end: date.format('YYYY-MM-DD 15:00:00') },
                { name: "15:00 - 18:00", start: date.format('YYYY-MM-DD 15:00:00'), end: date.format('YYYY-MM-DD 18:00:00') },
                { name: "18:00 - 21:00", start: date.format('YYYY-MM-DD 18:00:00'), end: date.format('YYYY-MM-DD 21:00:00') },
                { name: "21:00 - 00:00", start: date.format('YYYY-MM-DD 21:00:00'), end: date.format('YYYY-MM-DD 23:59:59') },
            ]
        }

        if (filter === "week") {
            const weeks = ["Понидельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]
            weeks.forEach((week, key) => {
                const startDate = moment(new Date(start)).add(key, 'days').format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(start)).add(key, 'days').format('YYYY-MM-DD 23:59:59')
                list.push({ name: week, start: startDate, end: endDate })
            })
        }

        if (filter === "month") {

            const startDate = moment(new Date(start)).subtract(1, 'days')
            const endDate = moment(new Date(end))

            const difference = endDate.diff(startDate, 'days')

            let count = 0
            Array.from(Array(difference).keys()).forEach(key => {

                const startPeriod = moment(new Date(start)).add(key, 'days')
                const week = startPeriod.week()

                if (week !== count) {

                    let endPeriod = moment(new Date(startPeriod)).endOf('week')

                    if (!endPeriod.isSame(startPeriod, 'months')) {
                        endPeriod = moment(new Date(startPeriod)).endOf('month')
                    }

                    const startWeek = startPeriod.format('YYYY-MM-DD 00:00:00')
                    const endWeek = endPeriod.format('YYYY-MM-DD 23:59:59')
                    const name = `${startPeriod.format('DD MMM')} - ${endPeriod.format('DD MMM')}`

                    list.push({ name, start: startWeek, end: endWeek })

                    count = week
                }
            })
        }

    }

    return list
}




export const setStatisticsDate = (type, filter, start, end) => {

    if (type && filter && start && end) {

        /* Prev */
        if (type === "prev") {

            if (filter === "day") {
                const startDate = moment(new Date(start)).subtract(1, "days").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).subtract(1, "days").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "week") {
                const startDate = moment(new Date(start)).subtract(1, "weeks").startOf('week').format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).subtract(1, "weeks").endOf('week').format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "month") {
                const startDate = moment(new Date(start)).subtract(1, "months").startOf('month').format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).subtract(1, "months").endOf('month').format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

        }

        /* Prev */
        if (type === "next") {

            if (filter === "day") {
                const startDate = moment(new Date(start)).add(1, "days").format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).add(1, "days").format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "week") {
                const startDate = moment(new Date(start)).add(1, "weeks").startOf('week').format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).add(1, "weeks").endOf('week').format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

            if (filter === "month") {
                const startDate = moment(new Date(start)).add(1, "months").startOf('month').format('YYYY-MM-DD 00:00:00')
                const endDate = moment(new Date(end)).add(1, "months").endOf('month').format('YYYY-MM-DD 23:59:59')
                return { start: startDate, end: endDate }
            }

        }

    }

}


/* Get Date */
export const modifyDate = (filter, start, end) => {

    if (filter && start && end) {

        if (filter === "month" || filter === "week") {
            const startYear = moment(new Date(start)).isSame(moment(), "year") ? "" : " " + moment(new Date(start)).format("YYYY")
            const endYear = moment(new Date(end)).isSame(moment(), "year") ? "" : " " + moment(new Date(end)).format("YYYY")
            return moment(new Date(start)).format("DD MMM") + startYear + " - " + moment(new Date(end)).format("DD MMM") + endYear
        }

        if (filter === "day") {
            const today = moment(new Date(start)).isSame(moment(), 'day') ? "Сегодня, " : ""
            const yesterday = moment(new Date(start)).isSame(moment().subtract(1, 'days'), 'day') ? "Вчера, " : ""
            return `${today}${yesterday}${moment(new Date(start)).format("DD MMM")}`
        }

    }

    return ""

}


/* Generate password */
export const generatePassword = () => {

    const length = 8
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!+_~@$%&*()"
    let password = ""

    for (var i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n))
    }

    return password
}



/* TRANSLIT */
export const translit = word => {

    let text = ''

    const converter = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
        'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya',

        'А': 'a', 'Б': 'b', 'В': 'b', 'Г': 'g', 'Д': 'd',
        'Е': 'e', 'Ё': 'e', 'Ж': 'zh', 'З': 'z', 'И': 'i',
        'Й': 'y', 'К': 'k', 'Л': 'l', 'М': 'm', 'Н': 'n',
        'О': 'o', 'П': 'p', 'Р': 'r', 'С': 's', 'Т': 't',
        'У': 'u', 'Ф': 'f', 'Х': 'H', 'Ц': 'C', 'Ч': 'ch',
        'Ш': 'sh', 'Щ': 'sch', 'Ь': '', 'Ы': 'y', 'Ъ': '',
        'Э': 'e', 'Ю': 'yu', 'Я': 'ya'
    }


    for (let i = 0; i < word.length; ++i) {
        if (word[i] === " ") {
            text += "-"
        }
        else if (converter[word[i]] === undefined || converter[word[i]] === null) {
            text += word[i]
        }
        else {
            text += converter[word[i]]
        }
    }

    return String(text).toLowerCase().replace(/[^a-zA-Z0-9-]/g, '')
}
