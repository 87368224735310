const data = [
    { name: 'dashboard', text: 'Dashboard', link: '/', image: <img src="/images/home.png" alt="Home" /> },
    { name: 'space' },
    { name: 'requests', text: 'Заявки', link: '/requests', image: <img src="/images/quote-request.png" alt="Request" /> },
    { name: 'space' },
    { name: 'category', text: 'Категории', link: '/categories', image: <img src="/images/category.png" alt="Category" /> },
    { name: 'products', text: 'Каталог', link: '/products', image: <img src="/images/product.png" alt="Product" /> },
    { name: 'parts', text: 'Запчасти', link: '/parts', image: <img src="/images/parts.png" alt="Parts" /> },
    { name: 'brands', text: 'Бренды', link: '/brands', image: <img src="/images/brand.png" alt="Brand" /> },
    { name: 'space' },
    { name: 'admin', text: 'Администраторы', link: '/admin', image: <img src="/images/person.png" alt="Person" /> },
    { name: 'settings', text: 'Настройки', link: '/settings', image: <img src="/images/setting.png" alt="Setting" /> },
]

export { data }