import React, { Component } from "react"

/* Components */
import { Input, DataLayout } from "../../components"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { get, save } from '../../api/Settings'

/* Sweetalert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter, utils } from "../../helpers"



/* Page Settings */
class Settings extends Component {

    constructor() {
        super()

        this.state = {

            videoDelay: "",
            gameDelay: "",
            pincode: "",

            status: "loading",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: "loading" })
        }

        get().then(response => {
            if (response.status === 200) {
                this.setState({ 
                    status: "ok", 
                    videoDelay: utils.check(response.data.videoDelay, ""), 
                    gameDelay: utils.check(response.data.gameDelay, ""),
                    pincode: utils.check(response.data.pincode, "") 
                })
            }
            else {
                this.setState({ status: "error" })
            }
        }).catch(() => {
            this.setState({ status: "network" })
        })
    }

    /* Save action */
    save = () => {

        const { pincode, videoDelay, gameDelay } = this.state

        if (videoDelay === "") {
            this._videoDelay.error("Введите данные")
            return
        }

        if (!/^-?\d+$/.test(videoDelay)) {
            this._videoDelay.error("Введите цифру")
            return
        }

        if (videoDelay > 300 || videoDelay < 30) {
            this._videoDelay.error("Длительность должен быть между 30 и 300 секунд")
            return
        }

        if (gameDelay === "") {
            this._gameDelay.error("Введите данные")
            return
        }

        if (!/^-?\d+$/.test(gameDelay)) {
            this._gameDelay.error("Введите цифру")
            return
        }

        if (gameDelay > 60 || gameDelay < 5) {
            this._gameDelay.error("Длительность должен быть между 5 и 60 секунд")
            return
        }

        if (pincode === "") {
            this._pincode.error("Введите ПИН код дилера")
            return
        }

        if (!/^-?\d+$/.test(pincode)) {
            this._pincode.error("Введите цифру")
            return
        }

        if (pincode.length !== 6) {
            this._pincode.error("ПИН-код должен содержать 6 символов")
            return
        }

        this.setState({ waiting: true })

        save({ pincode, videoDelay, gameDelay }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Настройки успешно сохранены!", "success")).then(() => {
                    this.setState({ waiting: false })
                })
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            this.setState({ waiting: false })
        })
    }


    /* Draw Content */
    _content = () => {

        const { status, videoDelay, gameDelay, pincode, waiting } = this.state

        if (status !== "ok") {
            return <DataLayout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page-content">

                <div className="page-form">
                    <div className="page-form-box">
                        <Input ref={ref => this._videoDelay = ref} label="Запустить видеоролики после бездействие (секунд)" value={videoDelay} onChange={event => this.setState({ videoDelay: event.target.value })} />
                        <Input ref={ref => this._gameDelay = ref} label="Показ результата после завершение игры (секунд)" value={gameDelay} onChange={event => this.setState({ gameDelay: event.target.value })} />
                        <Input ref={ref => this._pincode = ref} label="PIN code для ROYAL FLUSH" value={pincode} onChange={event => this.setState({ pincode: event.target.value })} />
                    </div>
                </div>

                <div className="page-form-bottom">
                    {waiting
                        ? <div className="page-form-button"><LoadingOutlined /></div>
                        : <div onClick={() => this.save()} className="page-form-button">Сохранить</div>
                    }
                </div>

            </div>
        )
    }

    render = () => {

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Настройки</div>
                </div>

                {this._content()}

            </div>
        )
    }

}

export default withRouter(Settings)