import React, { Component } from "react"

/* Router */
import { Link } from "react-router-dom"

/* Helpers */
import { withRouter } from '../helpers'

/* JWT */
import { decodeToken } from 'react-jwt'


/* Component Header */
class Header extends Component {

    constructor() {
        super()

        this.state = {
            visible: false
        }
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside, true)
    }

    /* Click Outside Handler */
    handleClickOutside = event => {
        if (this._menu && !this._menu.contains(event.target)) {
            this.setState({ visible: false })
        }
    }

    /* Logout action */
    logout = () => {
        const { setToken, navigate } = this.props
        localStorage.removeItem("token")
        setToken(null)
        navigate('/')
    }

    render = () => {

        const { visible } = this.state
        const { token } = this.props
        const user = decodeToken(token)

        return (
            <div style={{ overflow: 'hidden' }}>

                <div className="header">

                    {user !== null && user !== undefined &&
                        <div onClick={() => this.setState({ visible: !visible })} className="header-user">

                            <img src="/images/profile-user.png" alt="Profile" />
                            <img src="/images/user.png" alt="User" className="mobile-image" />

                            <div className="header-user-text">
                                <div className="header-user-name">{user.name}</div>
                                <div className="header-user-role">Администратор</div>
                            </div>

                            <div className="header-more">
                                <img src="/images/more.png" alt="More" />
                            </div>

                        </div>
                    }

                </div>

                <div ref={ref => this._menu = ref} className={`header-dropdown-menu ${visible ? 'active' : ''}`}>

                    <Link onClick={() => this.setState({ visible: false })} to="/profile" className="header-dropdown-menu-item">
                        <img src="/images/person.png" alt="Avatar" />
                        <span>Профиль</span>
                    </Link>

                    <div className="header-dropdown-menu-divider" />

                    <Link onClick={() => this.setState({ visible: false })} to="/change-password" className="header-dropdown-menu-item">
                        <img src="/images/padlock.png" alt="Padlock" />
                        <span>Изменить пароль</span>
                    </Link>

                    <div className="header-dropdown-menu-divider" />

                    <div onClick={() => this.logout()} className="header-dropdown-menu-item">
                        <img src="/images/exit.png" alt="Exit" />
                        <span>Выйти</span>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(Header)