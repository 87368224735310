import React, { Component } from "react"

/* JWT */
import { decodeToken } from 'react-jwt'

/* Helpers */
import { withRouter } from '../../helpers'



/* Page Dashboard */
class Dashboard extends Component {

    /* Logout action */
    logout = () => {
        const { setToken, navigate } = this.props
        localStorage.removeItem("token")
        setToken(null)
        navigate('/')
    }

    render = () => {

        const { token } = this.props
        const user = decodeToken(token)

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Dashboard</div>
                </div>

                {user !== null && user !== undefined &&
                    <div className="dashboard-panel">
                        <div className="dashboard-text">Здравствуйте, <span>{user.name}</span></div>
                        <div onClick={() => this.logout()} className="dashboard-logout">Выйти из системы</div>
                    </div>
                }

            </div>
        )
    }

}

export default withRouter(Dashboard)