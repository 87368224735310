import React, { Component } from "react"

/* Link */
import { NavLink } from 'react-router-dom'

/* Constants */
import { menu } from '../constants'



/* Component Mobile Tab */
class MobileTab extends Component {

    constructor() {
        super()

        this.state = {
            visible: false
        }
    }

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutside, true)
    }


    /* Click Outside Handler */
    handleClickOutside = event => {
        if (this._bar && !this._bar.contains(event.target)) {
            this.setState({ visible: false })
        }
    }


    render = () => {

        const { visible } = this.state

        return (
            <div className="mobile-menu-container">

                <div className="mobile-menu">

                    {menu.data.map((item, index) => {

                        if (item.name === "space" || index > 5) {
                            return <div key={`${index}`} />
                        }

                        return (
                            <NavLink onClick={() => this.setState({ visible: false })} key={`${index}`} to={item.link} className="mobile-menu-item">
                                <div className="mobile-menu-image">
                                    {item.image}
                                </div>
                            </NavLink>
                        )
                    })}

                    <div onClick={() => this.setState({ visible: !visible })} className={`mobile-menu-item ${visible ? 'active' : ''}`}>
                        <div className="mobile-menu-image">
                            <img src="/images/dots.png" alt="More" />
                        </div>
                    </div>

                </div>

                <div ref={ref => this._bar = ref} className={`mobile-menu-bar ${visible ? 'active' : ''}`}>
                    {menu.data.map((item, index) => {

                        if (item.name === "space" || index < 6) {
                            return <div key={`${index}`} />
                        }

                        return (
                            <NavLink onClick={() => this.setState({ visible: false })} key={`${index}`} to={item.link} className="mobile-menu-bar-item">
                                <div className="mobile-menu-bar-image">
                                    {item.image}
                                </div>
                                <span>{item.text}</span>
                            </NavLink>
                        )
                    })}
                </div>
            </div>
        )
    }

}

export default MobileTab