import React from 'react'
import ReactDOM from 'react-dom/client'

/* CSS */
import './design/css/app.css'
import './design/css/authorization.css'
import './design/css/input.css'
import './design/css/header.css'
import './design/css/page404.css'
import './design/css/page.css'
import './design/css/menu.css'
import './design/css/layout.css'
import './design/css/pagination.css'
import 'react-datepicker/dist/react-datepicker.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()
