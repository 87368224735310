import axios from "axios"
import { env } from '../constants'

/* Get */
export const get = () => {
    const uri = `${env.startpoint}admin/settings/get`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(data => data.response)
}

/* Save */
export const save = data => {
    const uri = `${env.startpoint}admin/settings/save`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}