import React, { Component } from "react"

/* Components */
import { Input, TextArea } from '../../components'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { create, categories } from '../../api/Product'

/* Sweetalert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter, utils } from "../../helpers"


/* Page Create Product */
class CreateProduct extends Component {

    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            brands: [],
            name: "",
            slug: "",
            articul: "",
            categoryID: 0,
            brandID: 0,
            image: "",
            type: props.type,
            description: "",
            metaDescription: "",
            metaKeywords: "",
            gallery: [],
            characteristics: [],
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load action */
    load = () => {
        categories().then(response => {
            if (response.status === 200) {
                this.setState({ categories: response.data.categories, brands: response.data.brands })
            }
        })
    }

    /* Create action */
    create = () => {

        const { name, slug, image, type, articul, description, gallery, categoryID, brandID, metaDescription, metaKeywords, characteristics } = this.state
        const { navigate } = this.props

        if (name === "") {
            this._name.error("Введите название")
            return
        }

        if (slug === "") {
            this._slug.error("Введите Key (URL)")
            return
        }

        const data = new FormData()
        data.append('image', image)
        if (gallery && Array.isArray(gallery) && gallery.length > 0) {
            gallery.forEach(image => {
                data.append('gallery', image)
            })
        }
        data.append('name', name)
        data.append('slug', slug)
        data.append('type', type)
        data.append('articul', articul)
        data.append('description', description)
        data.append('categoryID', categoryID)
        data.append('brandID', brandID)
        data.append('metaKeywords', metaKeywords)
        data.append('metaDescription', metaDescription)
        data.append('characteristics', JSON.stringify(characteristics))

        this.setState({ waiting: true })

        create(data).then(response => {

            if (response.status === 200) {
                Swal.fire(utils.notification("Продукт успешно создан!", "success")).then(() => {
                    this.setState({ waiting: false })
                    navigate(-1)
                })
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            this.setState({ waiting: false })
        })
    }


    /* Upload Image */
    upload = event => {
        let file = event.target.files[0]

        if (file) {
            this.setState({ image: file })
        }
    }

    /* Set Name */
    setName = name => {
        const slug = utils.translit(name)
        this.setState({ name, slug })
    }

    /* Uplaod Gallery */
    gallery = event => {

        const { gallery } = this.state
        let files = event.target.files

        for (const file of files) {
            gallery.push(file)
        }

        this.setState({ gallery })
    }

    /* Remove Image */
    removeImage = (type = "default", index) => {
        if (type === "default") {
            const { gallery } = this.state
            gallery.splice(index, 1)
            this.setState({ gallery })
        }
    }

    /* Draw Gallery */
    _gallery = () => {

        const { gallery } = this.state

        return (
            <div className="gallery-container">
                <div className="gallery-text">Галерея</div>

                <div className="gallery-box">

                    {gallery.map((item, index) =>
                        <div className="gallery-item" key={`${index}`}>
                            <img src={URL.createObjectURL(item)} alt="Gallery" />
                            <div onClick={() => this.removeImage("default", index)} className="gallery-item-remove">
                                <img src="/images/error.png" alt="Error" />
                            </div>
                        </div>
                    )}

                    <label className="gallery-input-file">
                        <input onChange={event => this.gallery(event)} multiple={true} type="file" name="file" accept="image/png, image/jpeg" />
                        <span className="gallery-input-file-button">Выберите файл</span>
                    </label>

                </div>
            </div>
        )
    }


    /* Add Characteristics */
    addCharacteristics = async () => {

        const { characteristics } = this.state

        const { value } = await Swal.fire({
            title: 'Добавить характеристики',
            html:
                `
                    <div class="swal-form">
                        <input type="text" id="name" class="swal2-input" placeholder="Название характеристики">
                        <input type="text" id="value" class="swal2-input" placeholder="Значение">
                    </div>
                `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#9a0b0d',
            reverseButtons: true,
            cancelButtonText: 'Отмена',
            confirmButtonText: 'Добавить',
            preConfirm: () => {

                const name = document.getElementById('name').value
                const value = document.getElementById('value').value

                if (name === "" || name === undefined || name === null) {
                    Swal.showValidationMessage(`Введите название характеристики`)
                    return false
                }
                if (value === "" || value === undefined || value === null) {
                    Swal.showValidationMessage(`Введите значение характеристики`)
                    return false
                }

                return { name, value }
            }
        })

        characteristics.push(value)
        this.setState({ characteristics })
    }

    /* Remove characteristics */
    removeCharacteristics = index => {
        const { characteristics } = this.state
        characteristics.splice(index, 1)
        this.setState({ characteristics })
    }

    /* Draw Characteristics */
    _characteristics = () => {

        const { characteristics } = this.state

        return (
            <div className="characteristic-container">
                <div className="characteristic-text">Характеристики</div>
                <div className="characteristic-box">
                    {characteristics.map((item, index) =>
                        <div className="characteristic-item" key={`${index}`}>
                            <div className="characteristic-name">{item.name}</div>
                            <div className="characteristic-value">{item.value}</div>
                            <div onClick={() => this.removeCharacteristics(index)} className="characteristic-item-remove">
                                <img src="/images/error.png" alt="Error" />
                            </div>
                        </div>
                    )}
                </div>
                <div onClick={() => this.addCharacteristics()} className="characteristic-button">Добавить</div>
            </div>
        )
    }


    render = () => {

        const { name, slug, articul, categoryID, brandID, image, waiting, description, metaDescription, metaKeywords, categories, brands } = this.state
        const { type } = this.props

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Добавить {type === "truck" ? "cпецтехнику" : "запчасть"}</div>
                </div>

                <div className="page-content">
                    <div className="page-form">
                        <div className="page-form-box">

                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setName(event.target.value)} />
                            <Input ref={ref => this._slug = ref} label="URL" value={slug} onChange={event => this.setState({ slug: event.target.value })} />
                            <Input ref={ref => this._articul = ref} label="Артикул" value={articul} onChange={event => this.setState({ articul: event.target.value })} />

                            <div className="page-form-label">Категория</div>
                            <select onChange={event => this.setState({ categoryID: event.target.value })} value={categoryID} defaultValue={0}>
                                <option selected={true} value={0} disabled>Не выбран</option>
                                {categories.map((category, index) =>
                                    <option key={`${index}`} value={category.id}>{category.name}</option>
                                )}
                            </select>

                            <div className="page-form-label">Бренд</div>
                            <select onChange={event => this.setState({ brandID: event.target.value })} value={brandID} defaultValue={0}>
                                <option selected={true} value={0} disabled>Не выбран</option>
                                {brands.map((brand, index) =>
                                    <option key={`${index}`} value={brand.id}>{brand.name}</option>
                                )}
                            </select>

                            <label className="content-input-file">
                                <div className="page-form-label">Изображение</div>
                                <input onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                <span className="content-input-file-button">Выберите файл</span>
                                <span className="content-input-file-text">
                                    {(image && image.name !== null) && <img src={URL.createObjectURL(image)} alt="Uploaded" />}
                                    {(image && image.name !== null) ? image.name : "Максимум 10MB"}
                                </span>
                            </label>

                        </div>
                        <div className="page-form-box">
                            {this._gallery()}
                            {this._characteristics()}
                            <TextArea label="Описание" value={description} onChange={event => this.setState({ description: event.target.value })} />
                            <TextArea label="Meta Keywords" value={metaKeywords} onChange={event => this.setState({ metaKeywords: event.target.value })} />
                            <TextArea label="Meta Description" value={metaDescription} onChange={event => this.setState({ metaDescription: event.target.value })} />
                        </div>
                    </div>

                    <div className="page-form-bottom centered">
                        {waiting
                            ? <div className="page-form-button"><LoadingOutlined /></div>
                            : <div onClick={() => this.create()} className="page-form-button">Добавить</div>
                        }
                    </div>

                </div>


            </div>
        )
    }

}

export default withRouter(CreateProduct)