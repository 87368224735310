/* Router */
import { Navigate } from "react-router-dom"

/* JWT */
import { isExpired } from "react-jwt"


const ProtectedRoute = ({ children }) => {
    
    const token = localStorage.getItem("token")

    if (!token || isExpired(token)) {
        return <Navigate to="/" />
    }

    return children
}

export default ProtectedRoute 