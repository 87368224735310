import React, { Component } from "react"


/* Component TextArea */
class TextArea extends Component {

    constructor() {
        super()

        this.state = {
            focused: false,
            secured: true,
            error: ""
        }
    }


    /* Show Error */
    error = error => this.setState({ error })

    /* On Focus */
    focus = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: true, error: "" })
    }

    /* On Blur */
    blur = () => {

        const { clear } = this.props

        if (clear !== null && clear !== undefined) {
            clear()
        }

        this.setState({ focused: false, error: "" })
    }


    render = () => {

        const { value, label, secure, file, disabled, ...props } = this.props
        const { focused, error } = this.state

        if (disabled) {
            return (
                <div className="form-input-disabled">
                    <div className="form-input-disabled-label">{label}</div>
                    {value}
                </div>
            )
        }

        return (
            <div className={`form-input form-textarea ${(focused || value !== "") ? "form-input-focused" : ""}`}>

                <div onClick={() => !focused && this._input.focus()} className="form-input-label">{label}</div>

                <textarea
                    {...props}
                    value={value}
                    onFocus={() => this.focus()}
                    onBlur={() => this.blur()}
                    ref={ref => this._input = ref}
                ></textarea>

                {error !== "" && <div className="form-input-error">{error}</div>}

            </div>
        )
    }

}

export default TextArea