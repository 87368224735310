import React, { Component } from "react"

/* Components */
import { DataLayout, Pagination } from '../../components'

/* REST API */
import { list, remove } from '../../api/Product'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* Sweetalert */
import Swal from "sweetalert2"


/* Fields */
const TRUCK = "truck"


/* Page Products */
class Products extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = prevProps => {

        const { params, type } = this.props
        const { page } = params

        if (prevProps.params.page !== page || prevProps.type !== type) {
            this.load(true)
        }

    }

    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }

    /* Load action */
    load = (reload = false) => {

        const { type } = this.props
        const page = this.getPage()

        if (reload) {
            this.setState({ status: 'loading' })
        }

        list({ page, type }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }



    /* Remove action */
    remove = item => {

        const { data } = this.state

        Swal.fire({
            title: `Вы действительно хотите удалить?`,
            text: "Невозможно восстановить после удаление!",
            showCancelButton: true,
            cancelButtonColor: "#bbb",
            cancelButtonText: "Отменить",
            confirmButtonText: "Удалить",
            confirmButtonColor: "#f44336",
            reverseButtons: true
        }).then(result => {
            if (result.isConfirmed) {
                remove({ id: item.id }).then(response => {
                    if (response.status === 200) {

                        Swal.fire(utils.notification("Категория успешно удалена!", "success"))

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))
                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                    }
                    else {
                        Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                    }
                }).catch(() => {
                    Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                })
            }
        })

    }


    /* Draw Content */
    _content = () => {

        const { status, data, count } = this.state
        const { navigate, type } = this.props
        const page = this.getPage()

        if (status !== "ok" || data.length === 0) {
            return (
                <div className="page-content">
                    <DataLayout status={status} reload={() => this.load(true)} data={data} />
                </div>
            )
        }

        const url = type === "truck" ? "products" : "parts"

        return (
            <div className="page-content">

                <div className="page-category">

                    <div className="page-content-row head">
                        <div className="page-content-number">#</div>
                        <div className="page-content-item">Название</div>
                        <div className="page-content-item">URL</div>
                        <div className="page-content-item">Тип</div>
                        <div className="page-content-more"></div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-content-row" key={`${index}`}>
                            <div className="page-content-number">{(page - 1) * 10 + index + 1}</div>
                            <div className="page-content-item bold">{utils.check(item.name, "-")}</div>
                            <div className="page-content-item">{utils.check(item.slug, "-")}</div>
                            <div className="page-content-item">{utils.check(item.type, "-")}</div>
                            <div className="page-content-more">

                                <div className="page-content-more-icon">
                                    <img src="/images/more.png" alt="More" />
                                </div>

                                <div className="page-content-more-menu">

                                    <div onClick={() => navigate(`/${url}/edit/${item.id}`)} className="page-content-more-menu-item">
                                        <img src="/images/edit.png" alt="Edit" />
                                        <span>Изменить</span>
                                    </div>

                                    <div className="page-content-more-divider" />

                                    <div onClick={() => this.remove(item)} className="page-content-more-menu-item">
                                        <img src="/images/remove.png" alt="Remove" />
                                        <span>Удалить</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/${url}/${page}`)} />

                </div>

            </div>
        )
    }


    render = () => {

        const { navigate, type } = this.props
        const url = type === "truck" ? "products" : "parts"

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">

                    <div className="page-header-text">{type === TRUCK ? "Каталог" : "Запчасти"}</div>

                    <div onClick={() => navigate(`/${url}/create`)} className="page-header-add">
                        <img src="/images/plus.png" alt="Plus" />
                        <div className="page-header-add-text">Создать</div>
                    </div>

                </div>

                {this._content()}

            </div>
        )
    }

}

export default withRouter(Products)