import axios from "axios"
import { env } from '../constants'

/* List */
export const list = data => {
    const uri = `${env.startpoint}admin/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Create */
export const create = data => {
    const uri = `${env.startpoint}admin/create`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}

/* Remove */
export const remove = data => {
    const uri = `${env.startpoint}admin/remove`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}