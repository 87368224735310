import axios from "axios"
import { env } from '../constants'

/* List */
export const list = data => {
    const uri = `${env.startpoint}admin/category/list`
    const token = localStorage.getItem("token")
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(data => data.response)
}


/* REMOVE */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/category/remove`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/category/create`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* GET */
export const get = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/category/get`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* EDIT */
export const edit = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}admin/category/edit`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}