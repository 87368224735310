import React, { Component } from "react"

/* Components */
import { DataLayout, Pagination } from '../../components'

/* REST API */
import { list } from '../../api/Request'

/* Helpers */
import { withRouter, utils } from '../../helpers'


/* Page Requests */
class Requests extends Component {

    constructor() {
        super()

        this.state = {
            data: [],
            status: "loading",
            count: 0,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    componentDidUpdate = prevProps => {

        const { params } = this.props
        const { page } = params

        if (prevProps.params.page !== page) {
            this.load(true)
        }

    }

    /* Get Page */
    getPage = () => {

        const { params } = this.props
        const { page } = params

        if (Number.isInteger(parseInt(page))) {
            return parseInt(page)
        }

        return 1
    }

    /* Load action */
    load = (reload = false) => {

        const page = this.getPage()

        if (reload) {
            this.setState({ status: 'loading' })
        }

        list({ page }).then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", data: response.data.list, count: response.data.count })
            }
            else {
                this.setState({ status: "error", data: [], count: 0 })
            }
        }).catch(() => {
            this.setState({ status: "network", data: [], count: 0 })
        })

    }

    /* Draw Content */
    _content = () => {

        const { status, data, count } = this.state
        const { navigate } = this.props
        const page = this.getPage()

        if (status !== "ok" || data.length === 0) {
            return (
                <div className="page-content">
                    <DataLayout status={status} reload={() => this.load(true)} data={data} />
                </div>
            )
        }

        return (
            <div className="page-content">

                <div className="page-request">

                    <div className="page-content-row head">
                        <div className="page-content-number">#</div>
                        <div className="page-content-item">Имя</div>
                        <div className="page-content-item">Телефон</div>
                        <div className="page-content-item">Сообщение</div>
                    </div>

                    {data.map((item, index) =>
                        <div className="page-content-row" key={`${index}`}>
                            <div className="page-content-number">{(page - 1) * 10 + index + 1}</div>
                            <div className="page-content-item bold">{utils.check(item.name, "-")}</div>
                            <div className="page-content-item">{utils.check(item.phone, "-")}</div>
                            <div className="page-content-item">{utils.check(item.message, "-")}</div>
                        </div>
                    )}

                    <Pagination size={10} page={page} total={count} onPageChange={page => navigate(`/requests/${page}`)} />

                </div>

            </div>
        )
    }

    render = () => {

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Заявки</div>
                </div>

                {this._content()}

            </div>
        )
    }

}

export default withRouter(Requests)