import React, { Component } from "react"

/* Link */
import { Link, NavLink } from 'react-router-dom'

/* Constants */
import { menu } from '../constants'

/* Components */
import { Logo } from '../components'


/* Widget Menu */
class Menu extends Component {


    render = () => {

        const { minimized, toggle } = this.props

        return (
            <div className={`menu ${minimized ? 'minimized' : ''}`}>

                <div className="menu-inner">

                    <Link to="/" className="menu-logo">
                        <Logo />
                        <div className="menu-logo-text">
                            <div className="menu-logo-name">GENERAL MACHINERY GROUP</div>
                            <div className="menu-logo-subtext">DASHBOARD PANEL</div>
                        </div>
                    </Link>

                    <div className="menu-box">
                        {menu.data.map((item, index) => {

                            if (item.name === 'space') {
                                return <div className="menu-space" key={`${index}`} />
                            }

                            return (
                                <NavLink key={`${index}`} to={item.link} className="menu-item">
                                    <div className="menu-image">
                                        {item.image}
                                    </div>
                                    <span>{item.text}</span>
                                </NavLink>
                            )
                        })}
                    </div>

                </div>

                <div onClick={() => toggle()} className={`menu-arrow ${minimized ? 'active' : ''}`}>
                    <img src="/images/back-arrow.png" alt="Back arrow" />
                </div>

            </div>
        )
    }

}

export default Menu