import React, { Component } from "react"

/* Components */
import { Input, DataLayout } from "../../components"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { get, save } from '../../api/Profile'

/* Sweetalert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter, utils } from "../../helpers"



/* Page Profile */
class Profile extends Component {

    constructor() {
        super()

        this.state = {
            name: "",
            email: "",
            status: "loading",
            waiting: false
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (reload = false) => {

        if (reload) {
            this.setState({ status: "loading" })
        }

        get().then(response => {
            if (response.status === 200) {
                this.setState({ status: "ok", name: utils.check(response.data.name, ""), email: utils.check(response.data.email, "") })
            }
            else {
                this.setState({ status: "error", name: "", email: "" })
            }
        }).catch(() => {
            this.setState({ status: "network", name: "", email: "" })
        })
    }

    /* Save action */
    save = () => {

        const { name, email } = this.state
        const { navigate, setToken } = this.props

        if (name === "" || name === null || name === undefined) {
            this._name.error("Введите имя")
            return
        }

        if (email === "" || email === null || email === undefined) {
            this._email.error("Введите Email")
            return
        }

        if (/\S+@\S+\.\S+/.test(email) === false) {
            this._email.error("Неверный формат Email")
            return
        }

        this.setState({ waiting: true })

        save({ name, email }).then(response => {
            if (response.status === 200) {
                setToken(response.data.token)
                Swal.fire(utils.notification("Профиль успешно сохранен!", "success")).then(() => {
                    this.setState({ waiting: false })
                    navigate(-1)
                })
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            this.setState({ waiting: false })
        })
    }


    /* Draw Content */
    _content = () => {

        const { status, name, email, waiting } = this.state

        if (status !== "ok") {
            return <DataLayout status={status} reload={() => this.load(true)} />
        }

        return (
            <div className="page-content">
                <div className="page-form">
                    <div className="page-form-box">
                        <Input ref={ref => this._name = ref} label="Имя" value={name} onChange={event => this.setState({ name: event.target.value })} />
                        <Input disabled ref={ref => this._email = ref} label="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                    </div>
                </div>

                <div className="page-form-bottom">
                    {waiting
                        ? <div className="page-form-button"><LoadingOutlined /></div>
                        : <div onClick={() => this.save()} className="page-form-button">Сохранить</div>
                    }
                </div>

            </div>
        )
    }

    render = () => {

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Профиль</div>
                </div>

                {this._content()}

            </div>
        )
    }

}

export default withRouter(Profile)