import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'


/* Component Data Layout */
class DataLayout extends Component {

    /* Draw button */
    _button = () => {

        const { reload } = this.props

        if (reload) {
            return <div onClick={() => reload()} className="button">Повторить</div>
        }
    }

    render = () => {

        const { status, data } = this.props

        if (status === "error") {
            return (
                <div className="data-layout-box">
                    <img src="/images/alert.png" alt="Alert" />
                    <h2>Ошибка сервера!</h2>
                    <p>Что-то пошло не так! Попробуйте позднее.</p>
                    {this._button()}
                </div>
            )
        }

        if (status === "network") {
            return (
                <div className="data-layout-box">
                    <img src="/images/wifi.png" alt="Wifi" />
                    <h2>Ошибка соединения!</h2>
                    <p>Проверьте подключение к сети.</p>
                    {this._button()}
                </div>
            )
        }

        if (status === "loading") {
            return (
                <div className="data-layout-box">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data && Array.isArray(data) && data.length === 0) {
            return (
                <div className="data-layout-box">
                    <img src="/images/empty-set.png" alt="Empty Set" />
                    <h2>Пусто!</h2>
                    <p>Пока здесь ничего нету.</p>
                </div>
            )
        }

        return <div />
    }

}

export default DataLayout