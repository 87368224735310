import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Components */
import { Input, Logo } from '../components'

/* REST API */
import { login } from '../api/Authorization'

/* Swal */
import Swal from "sweetalert2"

/* Helpers */
import { utils } from "../helpers"



/* Authorization */
class Authorization extends Component {

    constructor() {
        super()

        this.state = {
            email: "",
            password: "",
            waiting: false,
        }
    }

    /* Login action */
    login = () => {

        const { email, password } = this.state
        const { setToken } = this.props

        if (email === "" || email === null || email === undefined) {
            this._email.error("Введите Email")
            return
        }

        if (/\S+@\S+\.\S+/.test(email) === false) {
            this._email.error("Неверный формат Email")
            return
        }

        if (password === "") {
            this._password.error("Введите пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        this.setState({ waiting: true })

        this._password.error("")
        this._email.error("")

        /* SEND REQUEST to REST API */
        login({ email, password }).then(response => {
            if (response.status === 200) {
                setToken(response.data.token)
                localStorage.setItem("token", response.data.token)
            }
            else if (response.status === 400) {
                this.setState({ error: "Данные неверные!" })
            }
            else if (response.status === 404) {
                this._email.error("Пользователь не найден!")
            }
            else if (response.status === 401) {
                this._password.error("Неверный пароль!")
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
        }).finally(() => {
            this.setState({ waiting: false })
        })

    }

    /* Draw button */
    _button = () => {

        const { waiting } = this.state

        if (waiting) {
            return <div className="authorization-button"><LoadingOutlined /></div>
        }

        return <div onClick={() => this.login()} className="authorization-button">Войти</div>
    }

    render = () => {

        const { email, password } = this.state

        return (
            <div className="authorization">
                <div className="authorization-panel">

                    {/* Logo */}
                    <div className="authorization-logo">
                        <Logo />
                        <div className="authorization-logo-text">
                            <div className="authorization-logo-name">GENERAL MACHINERY GROUP</div>
                            <div className="authorization-logo-subtext">DASHBOARD PANEL</div>
                        </div>
                    </div>

                    <Input ref={ref => this._email = ref} label="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                    <Input ref={ref => this._password = ref} label="Пароль" value={password} onChange={event => this.setState({ password: event.target.value })} secure />

                    {this._button()}

                </div>
            </div>
        )
    }

}

export default Authorization