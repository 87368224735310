import React, { Component } from "react"

/* Components */
import { Input, TextArea } from '../../components'

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { create } from '../../api/Brand'

/* Sweetalert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter, utils } from "../../helpers"

/* Page Create Brand */
class CreateBrand extends Component {

    constructor() {
        super()

        this.state = {
            name: "",
            slug: "",
            image: "",
            metaDescription: "",
            metaKeywords: "",
            waiting: false
        }
    }

    /* Create action */
    create = () => {

        const { name, slug, image, metaDescription, metaKeywords } = this.state
        const { navigate } = this.props

        if (name === "") {
            this._name.error("Введите название")
            return
        }

        if (slug === "") {
            this._slug.error("Введите Key (URL)")
            return
        }

        const data = new FormData()
        data.append('image', image)
        data.append('name', name)
        data.append('slug', slug)
        data.append('metaKeywords', metaKeywords)
        data.append('metaDescription', metaDescription)

        this.setState({ waiting: true })

        create(data).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Бренд успешно создан!", "success")).then(() => {
                    this.setState({ waiting: false })
                    navigate(-1)
                })
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            this.setState({ waiting: false })
        })
    }


    /* Upload Image */
    upload = event => {
        let file = event.target.files[0]

        if (file) {
            this.setState({ image: file })
        }
    }

    /* Set Name */
    setName = name => {
        const slug = utils.translit(name)
        this.setState({ name, slug })
    }

    render = () => {

        const { name, slug, image, waiting, metaDescription, metaKeywords } = this.state

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Добавить бренд</div>
                </div>

                <div className="page-content">
                    <div className="page-form">
                        <div className="page-form-box">

                            <Input ref={ref => this._name = ref} label="Название" value={name} onChange={event => this.setName(event.target.value)} />
                            <Input ref={ref => this._slug = ref} label="URL" value={slug} onChange={event => this.setState({ slug: event.target.value })} />

                            <label className="content-input-file">
                                <input onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                <span className="content-input-file-button">Выберите файл</span>
                                <span className="content-input-file-text">
                                    {(image && image.name !== null) ? image.name : "Максимум 10MB"}
                                </span>
                            </label>

                        </div>
                        <div className="page-form-box">
                            <TextArea label="Meta Keywords" value={metaKeywords} onChange={event => this.setState({ metaKeywords: event.target.value })} />
                            <TextArea label="Meta Description" value={metaDescription} onChange={event => this.setState({ metaDescription: event.target.value })} />
                        </div>
                    </div>

                    <div className="page-form-bottom">
                        {waiting
                            ? <div className="page-form-button"><LoadingOutlined /></div>
                            : <div onClick={() => this.create()} className="page-form-button">Добавить</div>
                        }
                    </div>

                </div>


            </div>
        )
    }

}

export default withRouter(CreateBrand)