import React, { Component } from "react"

/* Components */
import { Input } from "../../components"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { password as changePassword } from '../../api/Profile'

/* Sweetalert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter, utils } from "../../helpers"



/* Page Password */
class Password extends Component {

    constructor() {
        super()

        this.state = {
            current: "",
            password: "",
            repeat: "",
            waiting: false
        }
    }

    /* Save action */
    save = () => {

        const { current, password, repeat } = this.state
        const { navigate } = this.props

        if (current === "" || current === null || current === undefined) {
            this._current.error("Введите пароль")
            return
        }

        if (current.length < 8) {
            this._current.error("Пароль должен быть больше 8 символов")
            return
        }

        if (password === "" || password === null || password === undefined) {
            this._password.error("Введите пароль")
            return
        }

        if (password.length < 8) {
            this._password.error("Пароль должен быть больше 8 символов")
            return
        }

        if (password !== repeat) {
            this._repeat.error("Пароли не совпадают")
            return
        }

        this.setState({ waiting: true })

        changePassword({ current, password }).then(response => {
            if (response.status === 200) {
                Swal.fire(utils.notification("Пароль успешно сохранен!", "success")).then(() => {
                    this.setState({ waiting: false })
                    navigate(-1)
                })
            }
            else if (response.status === 401) {
                this._current.error("Неверный пароль")
                this.setState({ waiting: false })
            }
            else {
                Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
                this.setState({ waiting: false })
            }
        }).catch(() => {
            Swal.fire(utils.notification("Что-то пошло не так!<br/>Попробуйте позднее.", "error"))
            this.setState({ waiting: false })
        })
    }

    /* Generate Password */
    generatePassword = () => {
        const password = utils.generatePassword()
        this.setState({ password, repeat: password })
    }

    /* Draw Content */
    _content = () => {

        const { current, password, repeat, waiting } = this.state

        return (
            <div className="page-content">

                <div className="page-form">
                    <div className="page-form-box">
                        <Input ref={ref => this._current = ref} label="Текущий пароль" value={current} onChange={event => this.setState({ current: event.target.value })} secure />
                        <div className="page-form-space" />
                        <Input ref={ref => this._password = ref} label="Новый пароль" value={password} onChange={event => this.setState({ password: event.target.value })} secure />
                        <div onClick={() => this.generatePassword()} className="form-generate-password">Сгенерировать пароль</div>
                        <Input ref={ref => this._repeat = ref} label="Повторите пароль" value={repeat} onChange={event => this.setState({ repeat: event.target.value })} secure />
                    </div>
                </div>

                <div className="page-form-bottom">
                    {waiting
                        ? <div className="page-form-button"><LoadingOutlined /></div>
                        : <div onClick={() => this.save()} className="page-form-button">Сохранить</div>
                    }
                </div>

            </div>
        )
    }

    render = () => {

        return (
            <div className="page">

                {/* Page Header */}
                <div className="page-header">
                    <div className="page-header-text">Изменить пароль</div>
                </div>

                {this._content()}

            </div>
        )
    }

}

export default withRouter(Password)