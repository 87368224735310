import React, { Component } from "react"

/* Layouts */
import { Authorization, Layout } from './layouts'

/* JWT */
import { isExpired } from "react-jwt"



/* Entry Point */
class App extends Component {

    constructor() {
        super()
        const token = localStorage.getItem("token")
        this.state = { token }
    }

    render = () => {

        const { token } = this.state

        if (!token || isExpired(token)) {
            return <Authorization setToken={token => this.setState({ token })} />
        }

        return <Layout token={token} setToken={token => this.setState({ token })} />
    }
}

export default App