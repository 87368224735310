import React, { Component } from "react"

/* Components */
import { Menu, MobileTab, Header } from '../components'

/* Router */
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

/* Helpers */
import { ProtectedRoute } from '../helpers'

/* Pages */
import { Dashboard, Page404 } from '../pages/Dashboard'
import { Profile, Password, Settings } from '../pages/Profile'
import { Admin, CreateAdmin } from '../pages/Admin'
import { Requests } from '../pages/Request'
import { Categories, CreateCategory, EditCategory } from '../pages/Category'
import { Brands, CreateBrand, EditBrand } from '../pages/Brand'
import { Products, CreateProduct, EditProduct } from '../pages/Products'


/* Layout */
class Layout extends Component {

    constructor() {
        super()

        const minimized = localStorage.getItem("minimized")

        this.state = {
            minimized: minimized && minimized === "yes"
        }
    }

    /* Toggle Menu */
    toggleMenu = value => {

        this.setState({ minimized: value })

        if (value) {
            localStorage.setItem("minimized", "yes")
        }
        else {
            localStorage.removeItem("minimized")
        }
    }


    render = () => {

        const { minimized } = this.state
        const { setToken, token } = this.props

        return (
            <Router>
                <div className="app">

                    <Menu minimized={minimized} toggle={() => this.toggleMenu(!minimized)} />

                    <div className={`container ${minimized ? 'minimized' : ''}`}>

                        <Header token={token} setToken={setToken} />

                        <Routes>

                            <Route path="*" element={<ProtectedRoute><Page404 /></ProtectedRoute>} />
                            <Route path="/" element={<ProtectedRoute><Dashboard token={token} setToken={setToken} /></ProtectedRoute>} />
                            <Route path="/profile" element={<ProtectedRoute><Profile setToken={setToken} /></ProtectedRoute>} />
                            <Route path="/change-password" element={<ProtectedRoute><Password /></ProtectedRoute>} />
                            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />

                            {/* Requests */}
                            <Route path="/requests" element={<ProtectedRoute><Requests /></ProtectedRoute>} />
                            <Route path="/requests/:page" element={<ProtectedRoute><Requests /></ProtectedRoute>} />

                            {/* Categories */}
                            <Route path="/categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
                            <Route path="/categories/:page" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
                            <Route path="/categories/create" element={<ProtectedRoute><CreateCategory /></ProtectedRoute>} />
                            <Route path="/categories/edit/:id" element={<ProtectedRoute><EditCategory /></ProtectedRoute>} />

                            {/* Brands */}
                            <Route path="/brands" element={<ProtectedRoute><Brands /></ProtectedRoute>} />
                            <Route path="/brands/:page" element={<ProtectedRoute><Brands /></ProtectedRoute>} />
                            <Route path="/brands/create" element={<ProtectedRoute><CreateBrand /></ProtectedRoute>} />
                            <Route path="/brands/edit/:id" element={<ProtectedRoute><EditBrand /></ProtectedRoute>} />

                            {/* Products */}
                            <Route path="/products" element={<ProtectedRoute><Products type="truck" /></ProtectedRoute>} />
                            <Route path="/products/:page" element={<ProtectedRoute><Products type="truck" /></ProtectedRoute>} />
                            <Route path="/products/create" element={<ProtectedRoute><CreateProduct type="truck" /></ProtectedRoute>} />
                            <Route path="/products/edit/:id" element={<ProtectedRoute><EditProduct type="truck" /></ProtectedRoute>} />

                            {/* Parts */}
                            <Route path="/parts" element={<ProtectedRoute><Products type="parts" /></ProtectedRoute>} />
                            <Route path="/parts/:page" element={<ProtectedRoute><Products type="parts" /></ProtectedRoute>} />
                            <Route path="/parts/create" element={<ProtectedRoute><CreateProduct type="parts" /></ProtectedRoute>} />
                            <Route path="/parts/edit/:id" element={<ProtectedRoute><EditProduct type="parts" /></ProtectedRoute>} />

                            {/* Admin Routes */}
                            <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                            <Route path="/admin/:page" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                            <Route path="/admin/create" element={<ProtectedRoute><CreateAdmin /></ProtectedRoute>} />

                        </Routes>

                    </div>

                    {/* Draw Mobile Tab */}
                    <MobileTab />

                </div>
            </Router>
        )
    }
}

export default Layout